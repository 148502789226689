
:root {
    --theme-color: #be9a78;
}

.theme-color {
    color: var(--theme-color);
}














